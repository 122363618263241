import React from 'react'
import styled from 'styled-components'

import { GlobalWidth } from '../styles/global-styles.js'

const FooterNode = styled.footer`
    ${GlobalWidth}
`

export default function Footer() {
    return(
        <FooterNode></FooterNode>
    )
}