import React from 'react';
import styled from 'styled-components';

import { GlobalWidth } from '../../styles/global-styles.js';

const MainContainer = styled.main`
    ${GlobalWidth}
`

export default function PostsLayout({children}) {
    return(
        <MainContainer>{children}</MainContainer>
    )
}