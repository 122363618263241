import React from 'react';
import styled from 'styled-components';

import Button from '../components/button.js';

import { Colors } from '../styles/colors.js';
import { GlobalWidth } from '../styles/global-styles.js';

const HeaderNode = styled.header`
    background-color: ${Colors.yellow};
    height: 55px;
`

const HomeButtonContainer = styled.div`
    flex-grow: 1;
    font-family: Staatliches, cursive;
    font-size: 1.8rem;
    margin: auto;
    color: ${Colors.grey};
`

const ButtonsContainer = styled.div`
    margin: auto;
    display: grid;
    grid-auto-flow: column;
    column-gap: 10px;
`

const HeaderContentContainer = styled.div`
    height: 100%;
    ${GlobalWidth}
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
`

const HomeButton = styled.div`
    transition: all .3s ease-in-out;
    display: inline-block;
    cursor: pointer;
    &:hover {
        color: ${Colors.lightRed}
    }
`


export default function Headers() {
    const PagesConfig = [
        {
          key: "posts",
          pageName: "Posts",
          url: ""
        },
        {
          key: "about",
          pageName: "About",
          url: ""
        },
        {
          key: "contact",
          pageName: "Contact Me",
          url: ""
        }
      ];
    const pageButtons = PagesConfig.map(page => <Button pageName={page.pageName} key={page.key} />);
    return(
        <HeaderNode>
            <HeaderContentContainer>
                <HomeButtonContainer>
                    <HomeButton>RUIMPREGO</HomeButton>
                </HomeButtonContainer>
                <ButtonsContainer>{pageButtons}</ButtonsContainer>
            </HeaderContentContainer>
        </HeaderNode>
    )
}