import React from 'react';
import styled from 'styled-components';

import { Colors } from '../styles/colors.js';

const NavButton = styled.span`
    font-family: Dosis, sans-serif;
    font-size: .8rem;
    border: 2px solid ${Colors.grey};
    color: ${Colors.grey};
    padding: 5px 12px;
    font-weight: 600;
    text-transform: uppercase;
    cursor: pointer;
    position: relative;
    text-shadow: 0px;
    transition: all .3s ease-in-out;

    &::before,
    &::after {
        content: '';
        position: absolute;
        transition: all .3s ease-in-out;
        background-color: red;
    }

    &::before {
        top: -2px;
        left: -2px;
        width: 0;
        height: 0;
    }

    &::after {
        bottom: -2px;
        right: -2px;
        width: 0;
        height: 0;
    }

    :hover {
        &::before,
        &::after {
            background-color: ${Colors.yellow};
        }
        &::before,
        &::after {
            width: 40%;
            height: 5px;
        }
    }
`

export default function Button(props) {
    return(
        <NavButton>{props.pageName}</NavButton>
    )
}