export const Colors = {
    'indigo': '#577590',
    'mintGreen': '#43aa8b',
    'green': '#90be6d',
    'yellow': '#f9c74f',
    'orange': '#f8961e',
    'darkOrange': '#f3722c',
    'lightRed': '#f94144',
    'grey': '#212121',
    'lightGrey': '#EDEDED'
};