import React from 'react';
import styled from 'styled-components';

import Header from './header.js';
import Footer from './footer.js';
import PostsLayout from './main/posts-layout.js';

import './style.css';

const LayoutContainer = styled.div`
    display: flex;
    flex-direction: column;
`

export class Layout extends React.Component {
    render() { 
        return (
            <React.Fragment>
                <LayoutContainer>
                    <Header />
                    <PostsLayout>{this.props.children}</PostsLayout>
                    <Footer />
                </LayoutContainer>
            </React.Fragment>
        );
    }
    componentDidUpdate() {
        window.alert("pau");
    }
}